import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/articles-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Moving to Windows from a Mac`}</h1>
    <p>{`It has been a long time since I had any windows machine to work on. I've been using Linux(ubuntu and mint) and the Mac for the last 8 years.`}</p>
    <p>{`So the thought of using a windows machine to code was a bit scary, there are a lot of people that used to complain about compatibility issues. Even on `}<a parentName="p" {...{
        "href": "https://opsdroid.dev"
      }}>{`opsdroid`}</a>{`, we had some issues with async loops on windows systems.`}</p>
    <p>{`Being away from Windows land, I wasn't sure how well things would work out, but since folks have been saying that the WSL (Windows Subsystem for Linux) was such a great thing that makes developing on windows machines a really great experience.`}</p>
    <h2>{`Why the move`}</h2>
    <p>{`Initially, I didn't think that the move would be a permanent one.`}</p>
    <p>{`I've been using the Macbook Pro (early 2015) to stream, record/edit the `}<a parentName="p" {...{
        "href": "https://landingintech.com"
      }}>{`Landing in Tech podcast`}</a>{` and to code. Last year I've upgraded the Macbook SSD to a 1TB Crucial P1 SSD, giving me a lot of space to do everything I needed.`}</p>
    <p>{`Streaming with a dual-core 2.7GHz laptop is hard - even doing code related things. I had to tweak every little bit on OBS to try and get the setup as performant as possible. It worked until recently - the new OBS version seems to be more CPU intensive - the MacBook was struggling.`}</p>
    <p>{`Also, when doing the talk with a guest for Landing in Tech live on Twitch. I've noticed a few hiccups that were getting annoying - this was mostly dropped frames here and there.`}</p>
    <p>{`Since I've been wanting to build my own desktop since I was 13 years old, I decided that this was the right time to do it.`}</p>
    <p>{`The initial idea was to build the desktop to use it while streaming only. But I decided to pick a good setup. Since the desktop is way more powerful than the old Macbook, I started to think that perhaps I could dual-boot Windows and Linux together.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4eb221879c3c78b8f614a9ae774f47ef/4b190/new-setup.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.44171779141104%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMFAQb/xAAVAQEBAAAAAAAAAAAAAAAAAAACA//aAAwDAQACEAMQAAABfkxDHTksmv/EABsQAAIDAAMAAAAAAAAAAAAAAAIDARESBBQi/9oACAEBAAEFAncjBdhm13WpOb9rMhH/xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPwEZ/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8BQn//xAAbEAACAwADAAAAAAAAAAAAAAAAAQIRIQMxof/aAAgBAQAGPwKom8eGy8Lk7bOykz//xAAbEAEAAwADAQAAAAAAAAAAAAABABEhMUFRkf/aAAgBAQABPyFfKxs7hgQBzTsDbdbldJz6GstfX2H1hP/aAAwDAQACAAMAAAAQsN//xAAXEQADAQAAAAAAAAAAAAAAAAAAARFB/9oACAEDAQE/EGaKH//EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQIBAT8Q5iR2/8QAHBAAAwEAAwEBAAAAAAAAAAAAAREhADFRYZGx/9oACAEBAAE/ED0YdswjIvizbgxnEAtAfmA2ZMiLgH3BpEPAx1NVq+2C2NANX3f/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "new setup",
            "title": "new setup",
            "src": "/static/4eb221879c3c78b8f614a9ae774f47ef/6aca1/new-setup.jpg",
            "srcSet": ["/static/4eb221879c3c78b8f614a9ae774f47ef/d2f63/new-setup.jpg 163w", "/static/4eb221879c3c78b8f614a9ae774f47ef/c989d/new-setup.jpg 325w", "/static/4eb221879c3c78b8f614a9ae774f47ef/6aca1/new-setup.jpg 650w", "/static/4eb221879c3c78b8f614a9ae774f47ef/4b190/new-setup.jpg 800w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`The new build`}</h2>
    <p>{`I wanted to keep the build between 1300-1400, so this is what I decided to get for the new setup:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amzn.to/3iyJaFo"
        }}>{`AMD Ryzen 7 3700X`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amzn.to/3iyJaFo"
        }}>{`ASUS TUF Gamming x570-Plus motherboard`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amzn.to/3iyw2QS"
        }}>{`Corsair Vengeance DDR4 3000 MHZ Ram - 8GB x 2`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amzn.to/3mmXjrA"
        }}>{`Frostflow 120 Series liquid Cooler`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amzn.to/2E5p3jm"
        }}>{`Gigabyte RTX 2070 super windforce OC 8GB`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amzn.to/35PW9zl"
        }}>{`Crucial P1 1TB SSD`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amzn.to/2Rtqoni"
        }}>{`CORSAIR - TX750M Semi-Modular ATX PSU - 750 W`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://amzn.to/2ZCHJP5"
        }}>{`MSI - MAG Vampiric 010 ATX Mid-Tower PC Case`}</a></li>
    </ul>
    <p>{`I know that I should have waited for the RTX 3070, but I was a bit too impatient and decided to get the gigabyte one. I'm also planning to upgrade the RAM in the next couple of months and add 32 GB to the system. I'm also getting a Bluetooth/wireless card since it was pretty cheap.`}</p>
    <p>{`Building the whole thing was pretty straightforward. The only weird thing was the first boot where the motherboard was giving me different errors on each boot. But luckily reconnecting all the things seem to have fixed the problem.`}</p>
    <p>{`I've also tested the machine by streaming one hour of Flight Simulator 2020. Everything went well, the temperatures were pretty stable - the fans weren't even running on full speed.`}</p>
    <h2>{`Must have things`}</h2>
    <p>{`After installing Windows, I asked on Twitter for suggestions on must-haves for a windows machine. `}</p>
    <p>{`A thing I've noticed was how often I relied on the `}<inlineCode parentName="p">{`cmd+space`}</inlineCode>{` to bring up the search bar to start a program.`}</p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/tfmoliveira"
      }}>{`Filipe Oliveira`}</a>{` suggested `}<a parentName="p" {...{
        "href": "https://github.com/microsoft/PowerToys"
      }}>{`PowerToys`}</a>{` to recreate the `}<inlineCode parentName="p">{`cmd+space`}</inlineCode>{` shortcut on Mac. I've been using all the time!`}</p>
    <p>{`I've also installed the following things:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://theflying.dev/digital-garden/setting-up-windows-termina/"
        }}>{`Windows Terminal with zsh`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://tldr.sh/"
            }}>{`tldr`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`Visual Studio Code with all the extensions needed`}
        <ul parentName="li">
          <li parentName="ul">{`Auto Rename Tag`}</li>
          <li parentName="ul">{`Bracket Pair Colorizer`}</li>
          <li parentName="ul">{`MDX`}</li>
          <li parentName="ul">{`Remote - WSL`}</li>
          <li parentName="ul">{`Vim`}</li>
          <li parentName="ul">{`Tinacious Design Theme`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://discord.com/"
        }}>{`Discord`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://brave.com/"
        }}>{`Brave browser`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/sindresorhus/refined-github"
            }}>{`Refined GitHub`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.adobe.com/creativecloud.html"
        }}>{`Adobe Creative Cloud`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://git-scm.com/download/win"
        }}>{`Git`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.vim.org/download.php"
        }}>{`Vim`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://obsidian.md/"
        }}>{`Obsidian for note taking`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.lastpass.com/"
        }}>{`Lastpass`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://justgetflux.com/"
        }}>{`f.lux to turn night shift mode`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://getsharex.com/"
        }}>{`ShareX - capture screen with the same shortcuts as the Mac`}</a></li>
    </ul>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/NMeuleman"
      }}>{`Nicky Meuleman`}</a>{` has curated a great list of `}<a parentName="p" {...{
        "href": "https://twitter.com/i/events/1227597472781959169?s=20"
      }}>{`useful programs to have on a windows machine`}</a>{`.`}</p>
    <p>{`The last thing I've done was customising the VSCode and Windows Terminal theme.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      